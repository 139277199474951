import React from 'react';

import styles from './styles.module.scss';

/* eslint-disable */
const FaoLogo = () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 295.5 56.7"
    style={{height: '60px'}}
  >
  <g>
	<path className={styles.st0} d="M28.6,56.6c-15.5,0-28-12.6-28-28c0-15.5,12.6-28,28-28c15.5,0,28,12.6,28,28C56.6,44,44,56.6,28.6,56.6z
		 M28.5,17.5c0,0,2.3,2.4,2.3,4.3c0,2.1-1.6,2.6-2.2,2.6c0,0-0.1,0-0.1,0h0c0,0-0.1,0-0.1,0c-0.5,0-2.2-0.5-2.2-2.6
		C26.2,19.9,28.4,17.5,28.5,17.5z M7.4,30.1h2.6v-4.7h4.3v-2H9.9v-2.6h4.4v-2h-7V30.1z M30.8,17.8h2.7L30.3,6.5h-3.4l-3.2,11.3h2.5
		l0.7-2.5h3.3L30.8,17.8z M29.7,13.4h-2.3l1.1-4.9h0.2L29.7,13.4z M41.5,24.5c0,3.3,1.5,5.8,4.8,5.8c3.3,0,4.8-2.5,4.8-5.8
		c0-3.3-1.6-5.8-4.8-5.8C43,18.6,41.5,21.1,41.5,24.5z M44.2,24.5c0-2.2,0.5-3.9,2.1-3.9c1.5,0,2.1,1.8,2.1,3.9s-0.6,3.9-2.1,3.9
		C44.8,28.4,44.2,26.6,44.2,24.5z M27.4,53.5c0.2-1.1,0.3-2.8,0.3-4.9c0-0.6,0-0.2,0-0.3c0-2.9-0.1-3.9-2-4.5c-3-1-4.2-4.3-3.5-8.5
		l-10-25.4c-5.2,4.6-8.5,11.3-8.5,18.7C3.6,41.9,14.2,52.8,27.4,53.5z M28.6,37.3c-4-0.9-5.4-4.5-5.4-7.8L14.4,8.1
		c-0.6,0.4-1.1,0.8-1.7,1.3c1.9,4.6,10.5,25,10.6,25.1c1.5,3.7,4.3,2.5,5,5.5c0.2,0.7,0.2,1.1,0.2,1.1h0.3c0,0,0-0.3,0.2-1.1
		c0.7-3,3.4-1.8,5-5.5c0,0,8.6-20.5,10.6-25.1c-0.5-0.4-1.1-0.9-1.7-1.3L34,29.5C34,32.7,32.5,36.3,28.6,37.3L28.6,37.3z M28.6,30.8
		c-1.7-0.4-3.8-2-4.1-4.2c-0.1-0.8-0.2-1-0.1-2.7L16.8,6.6c-0.6,0.3-1.2,0.7-1.8,1.1c2.3,5.1,9.3,20.9,9.3,20.9
		c1.1,2.3,3.1,2.4,3.7,3.8c0.4,0.8,0.4,1.6,0.4,1.6h0.3c0-0.1,0-0.8,0.4-1.6c0.7-1.4,2.6-1.4,3.7-3.8c0,0,7-15.7,9.3-20.9
		c-0.6-0.4-1.2-0.7-1.8-1.1l-7.6,17.3c0.1,1.6,0,1.9-0.1,2.7C32.4,28.8,30.3,30.4,28.6,30.8L28.6,30.8z M28.7,27.9l0.1-0.9
		c0.5-2.2,2.1-1.9,3.2-4.1c0,0,0,0,0,0c0-0.1,5.4-11.7,7.7-16.6c-3.3-1.7-7.1-2.6-11.1-2.6c-4,0-7.8,0.9-11.1,2.6
		c2.3,4.9,7.7,16.5,7.7,16.6c0,0,0,0,0,0c1.1,2.2,2.7,1.9,3.2,4.1l0.1,0.9H28.7z M53.5,28.6c0-7.4-3.3-14.1-8.5-18.7l-10,25.4
		c0.7,4.1-0.6,7.5-3.5,8.5c-1.9,0.6-2,1.6-2,4.5c0,0.2,0-0.2,0,0.3c0,2.1,0.2,3.8,0.3,4.9C43,52.8,53.5,41.9,53.5,28.6z M11.5,35.9
		c0,0,0.4,0.8,0.7,1.3c0.3,0.6,0.7,1.2,0.7,1.2l-0.7,0.4c0,0-0.5-0.8-1-1.8c-0.1,0.1-0.9,0.5-1,0.5c0.1,0.1,0.9,1.7,0.9,1.7
		l-0.7,0.4c0,0-0.8-1.6-0.9-1.7c-0.1,0.1-1.8,0.9-1.8,0.9l-0.4-0.8L11.5,35.9L11.5,35.9z M14.1,40.2l0.6,0.7l-3.6,3l-0.6-0.7
		L14.1,40.2L14.1,40.2z M17.5,43.4l0.7,0.5l-0.9,5l-0.8-0.5c0,0,0.2-1,0.2-1.1c0,0-0.5-0.3-1-0.6c-0.5-0.3-0.9-0.7-1-0.7
		c-0.1,0.1-1,0.6-1,0.6L13,45.9L17.5,43.4L17.5,43.4z M15.4,45.5c0.1,0.1,0.4,0.3,0.7,0.5c0.3,0.2,0.6,0.4,0.7,0.5
		c0-0.2,0.4-1.9,0.4-2.2C17.1,44.5,15.6,45.4,15.4,45.5z M20.5,45.2c0,0,0.8,0.4,1.9,0.8c0.7,0.3,1.4,0.5,2,0.6l-0.2,0.7
		c0,0-0.6-0.1-1.6-0.4c-0.1,0.2-1.3,3.7-1.3,3.7l-0.9-0.3c0,0,1.2-3.6,1.3-3.7c-0.9-0.3-1.5-0.7-1.5-0.7L20.5,45.2z M35.5,46.2
		c0.3,0.2,0.5,0.5,0.7,0.9c0.1,0.2,0.1,0.3,0.1,0.5c0,0.7-0.5,1.2-1.3,1.4c0,0-0.4,0.1-0.5,0.2c0,0.2,0.5,1.8,0.5,1.8l-0.8,0.2
		l-1.4-4.6l1.3-0.4C34.6,46,35.1,46,35.5,46.2z M33.8,47c0,0.1,0.4,1.3,0.4,1.4c0.2-0.1,0.6-0.2,0.6-0.2c0.1,0,0.4-0.2,0.6-0.4
		c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.2c-0.1-0.2-0.2-0.3-0.3-0.4c-0.3-0.1-0.6-0.1-0.8,0C34.2,46.9,33.9,47,33.8,47z M38.3,44.2
		l4.1,3l-0.7,0.5c0,0-0.8-0.6-0.9-0.7c0,0-0.5,0.3-0.9,0.6c-0.4,0.3-0.9,0.5-0.9,0.5c0,0.1,0.2,1.1,0.2,1.1l-0.8,0.4l-0.7-5
		L38.3,44.2z M38.8,47.4c0.1-0.1,0.4-0.2,0.8-0.4c0.3-0.2,0.5-0.3,0.6-0.4c-0.2-0.1-1.5-1.1-1.7-1.3C38.5,45.6,38.8,47.2,38.8,47.4z
		 M43,40l3.6,3.2L46,44c0,0-3.4-1-3.9-1.1c0.4,0.4,2.5,2.6,2.5,2.6L44,46l-3.4-3.4l0.8-0.7c0,0,3.2,1,3.8,1.1
		c-0.4-0.4-2.6-2.3-2.6-2.3L43,40z M44.7,37.6l4.2,2.4l-0.4,0.7l-4.2-2.4L44.7,37.6z M46.5,33.7l0.7,0.4l-0.1,0.1
		c-0.2,0.3-0.3,0.4-0.4,0.7c-0.1,0.1-0.1,0.4,0,0.6c0.1,0.1,0.1,0.2,0.3,0.2c0.3,0.1,0.6-0.2,0.9-0.5c0.4-0.4,1-1,1.9-0.7
		c0.9,0.3,1.2,1.1,0.8,2.2c-0.1,0.3-0.3,0.6-0.5,0.9l0,0.1l-0.7-0.3l0.1-0.1c0.1-0.1,0.4-0.5,0.5-0.8c0.1-0.3,0.1-0.8-0.4-1
		c-0.4-0.1-0.7,0.2-1,0.5c-0.4,0.4-0.9,0.9-1.7,0.7c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.4-0.2-0.8,0-1.3C46.1,34.3,46.2,34,46.5,33.7
		L46.5,33.7z"/>
</g>
<g>
	<path className={styles.st0} d="M71.7,19.3c2.3,0,4.4,1.7,4.4,5.7c0,4-2.1,5.7-4.4,5.7c-2.3,0-4.4-1.7-4.4-5.7C67.3,21,69.4,19.3,71.7,19.3z
		 M71.7,29.1C73,29.1,74,28,74,25s-1-4.1-2.3-4.1c-1.3,0-2.3,1.1-2.3,4.1S70.4,29.1,71.7,29.1z"/>
	<path className={styles.st0} d="M77.7,23.7c0-0.5,0-1-0.1-1.3h1.8c0,0.5,0.1,1,0.1,1.5h0c0.2-0.6,0.9-1.6,2.1-1.6c0.1,0,0.2,0,0.3,0v1.8
		c-0.2,0-0.3-0.1-0.6-0.1c-0.8,0-1.7,0.5-1.7,2v4.5h-2V23.7z"/>
	<path className={styles.st0} d="M83.3,31.8c0.5,0.3,1.4,0.7,2.4,0.7c1.8,0,2.2-1.3,2.2-2.6v-0.8h0c-0.3,0.6-0.9,1.4-2.2,1.4
		c-1.1,0-2.8-0.8-2.8-4c0-2.2,0.8-4.2,2.9-4.2c1.2,0,1.7,0.7,2.1,1.4H88c0-0.4,0-0.8,0.1-1.3h1.8c0,0.4-0.1,0.9-0.1,1.3v6
		c0,2.5-0.8,4.4-3.8,4.4c-1.3,0-2.3-0.3-2.8-0.5L83.3,31.8z M86.2,29.1c1.2,0,1.7-0.9,1.7-2.7c0-1.7-0.6-2.6-1.6-2.6
		c-1,0-1.4,1-1.4,2.6C84.9,28.3,85.5,29.1,86.2,29.1z"/>
	<path className={styles.st0} d="M92,22.9c0.6-0.3,1.3-0.6,2.6-0.6c2.5,0,3.1,1.2,3.1,3.3v3.6c0,0.6,0.1,1.1,0.1,1.4H96
		c-0.1-0.3-0.1-0.7-0.1-1.1h0c-0.5,0.7-1.1,1.2-2.3,1.2c-1.3,0-2.4-0.9-2.4-2.4c0-2.2,1.8-2.8,4.3-2.8h0.4v-0.3
		c0-0.8-0.4-1.5-1.5-1.5c-1,0-1.8,0.5-2.2,0.8L92,22.9z M95.8,26.5h-0.2c-1.7,0-2.7,0.3-2.7,1.5c0,0.7,0.5,1.2,1.2,1.2
		c1.2,0,1.6-0.9,1.6-2.3V26.5z"/>
	<path className={styles.st0} d="M99.5,23.7c0-0.5,0-1-0.1-1.3h1.8c0,0.4,0.1,0.8,0.1,1.2h0c0.3-0.5,0.8-1.4,2.2-1.4c1.8,0,2.4,1.4,2.4,3v5.2
		h-2v-4.9c0-1.2-0.3-1.8-1.1-1.8c-1,0-1.4,0.9-1.4,1.9v4.7h-2V23.7z"/>
	<path className={styles.st0} d="M107.8,18.9h2v1.9h-2V18.9z M107.9,22.4h2v8.1h-2V22.4z"/>
	<path className={styles.st0} d="M111.6,28.6c0.3,0.1,1.1,0.5,2,0.5c0.7,0,1.3-0.2,1.3-0.8c0-0.3-0.2-0.6-0.9-1l-0.8-0.5
		c-0.8-0.5-1.7-1-1.7-2.3c0-1.1,1.1-2.3,2.8-2.3c1,0,1.9,0.3,2,0.3l-0.1,1.5c-0.5-0.2-1-0.4-1.7-0.4c-0.8,0-1.1,0.3-1.1,0.8
		c0,0.4,0.3,0.7,0.9,1l1,0.6c1,0.6,1.5,1.1,1.5,2.1c0,1.5-1.3,2.4-3,2.4c-1,0-1.9-0.2-2.3-0.4L111.6,28.6z"/>
	<path className={styles.st0} d="M118.6,22.9c0.6-0.3,1.3-0.6,2.6-0.6c2.5,0,3.1,1.2,3.1,3.3v3.6c0,0.6,0.1,1.1,0.1,1.4h-1.8
		c-0.1-0.3-0.1-0.7-0.1-1.1h0c-0.5,0.7-1.1,1.2-2.3,1.2c-1.3,0-2.4-0.9-2.4-2.4c0-2.2,1.8-2.8,4.3-2.8h0.4v-0.3
		c0-0.8-0.4-1.5-1.5-1.5c-1,0-1.9,0.5-2.2,0.8L118.6,22.9z M122.5,26.5h-0.2c-1.7,0-2.7,0.3-2.7,1.5c0,0.7,0.5,1.2,1.2,1.2
		c1.2,0,1.6-0.9,1.6-2.3V26.5z"/>
	<path className={styles.st0} d="M126.9,20.7l2-0.6v2.3h1.6v1.4h-1.6V28c0,0.9,0.3,1.1,0.9,1.1c0.3,0,0.6-0.1,0.7-0.2v1.4
		c-0.3,0.1-0.7,0.3-1.3,0.3c-1.5,0-2.3-0.7-2.3-2.4v-4.5h-1.3v-1.4h1.3V20.7z"/>
	<path className={styles.st0} d="M131.8,18.9h2v1.9h-2V18.9z M131.9,22.4h2v8.1h-2V22.4z"/>
	<path className={styles.st0} d="M139.2,22.3c1.7,0,3.6,0.9,3.6,4.2c0,3.4-1.9,4.2-3.6,4.2c-1.7,0-3.6-0.8-3.6-4.2
		C135.6,23.1,137.5,22.3,139.2,22.3z M139.2,29.3c1.3,0,1.5-1.5,1.5-2.8s-0.3-2.8-1.5-2.8c-1.3,0-1.6,1.4-1.6,2.8
		S137.9,29.3,139.2,29.3z"/>
	<path className={styles.st0} d="M144.3,23.7c0-0.5,0-1-0.1-1.3h1.8c0,0.4,0.1,0.8,0.1,1.2h0c0.3-0.5,0.8-1.4,2.2-1.4c1.8,0,2.4,1.4,2.4,3v5.2
		h-2v-4.9c0-1.2-0.3-1.8-1.1-1.8c-1,0-1.4,0.9-1.4,1.9v4.7h-2V23.7z"/>
	<path className={styles.st0} d="M163.3,18.7v10.5c0,0.6,0,1.1,0.1,1.4h-1.8c0-0.2-0.1-0.7-0.1-1.3h0c-0.3,0.7-0.9,1.4-2.2,1.4
		c-2,0-2.9-1.9-2.9-4.2c0-2.7,1.1-4.2,2.8-4.2c1.2,0,1.8,0.7,2.1,1.3h0v-4.9H163.3z M159.8,29.2c1.2,0,1.5-1.2,1.5-2.8
		c0-1.6-0.4-2.7-1.5-2.7c-1.1,0-1.5,1.1-1.5,2.7C158.3,28.3,158.6,29.2,159.8,29.2z"/>
	<path className={styles.st0} d="M171.1,30.1c-0.4,0.2-1.3,0.5-2.4,0.5c-2.6,0-3.8-1.9-3.8-4.1c0-2.4,1.3-4.3,3.4-4.3c1.8,0,3.3,1,3.3,4.3V27
		h-4.7c0,1.4,0.6,2.2,2.1,2.2c1.2,0,1.8-0.4,2.1-0.6L171.1,30.1z M169.6,25.7c0-1.4-0.5-2.2-1.4-2.2c-1,0-1.5,1.1-1.5,2.2H169.6z"/>
	<path className={styles.st0} d="M172.8,28.6c0.3,0.1,1.1,0.5,2,0.5c0.7,0,1.3-0.2,1.3-0.8c0-0.3-0.2-0.6-0.9-1l-0.8-0.5
		c-0.8-0.5-1.7-1-1.7-2.3c0-1.1,1.1-2.3,2.8-2.3c1,0,1.9,0.3,2,0.3l-0.1,1.5c-0.5-0.2-1-0.4-1.7-0.4c-0.8,0-1.1,0.3-1.1,0.8
		c0,0.4,0.3,0.7,0.9,1l1,0.6c1,0.6,1.5,1.1,1.5,2.1c0,1.5-1.3,2.4-3,2.4c-1,0-1.9-0.2-2.3-0.4L172.8,28.6z"/>
	<path className={styles.st0} d="M183.8,19.5h2.4l3.7,8.7h0v-8.7h1.8v11h-2.4l-3.7-8.6h0v8.6h-1.8V19.5z"/>
	<path className={styles.st0} d="M194.2,22.9c0.6-0.3,1.3-0.6,2.6-0.6c2.5,0,3.1,1.2,3.1,3.3v3.6c0,0.6,0.1,1.1,0.1,1.4h-1.8
		c-0.1-0.3-0.1-0.7-0.1-1.1h0c-0.5,0.7-1.1,1.2-2.3,1.2c-1.3,0-2.4-0.9-2.4-2.4c0-2.2,1.8-2.8,4.3-2.8h0.4v-0.3
		c0-0.8-0.4-1.5-1.5-1.5c-1,0-1.8,0.5-2.2,0.8L194.2,22.9z M198,26.5h-0.2c-1.7,0-2.7,0.3-2.7,1.5c0,0.7,0.5,1.2,1.2,1.2
		c1.2,0,1.6-0.9,1.6-2.3V26.5z"/>
	<path className={styles.st0} d="M202.4,20.7l2-0.6v2.3h1.6v1.4h-1.6V28c0,0.9,0.3,1.1,0.9,1.1c0.3,0,0.6-0.1,0.7-0.2v1.4
		c-0.3,0.1-0.7,0.3-1.3,0.3c-1.5,0-2.3-0.7-2.3-2.4v-4.5h-1.3v-1.4h1.3V20.7z"/>
	<path className={styles.st0} d="M207.4,18.9h2v1.9h-2V18.9z M207.4,22.4h2v8.1h-2V22.4z"/>
	<path className={styles.st0} d="M214.7,22.3c1.7,0,3.6,0.9,3.6,4.2c0,3.4-1.9,4.2-3.6,4.2s-3.6-0.8-3.6-4.2C211.1,23.1,213,22.3,214.7,22.3z
		 M214.7,29.3c1.3,0,1.6-1.5,1.6-2.8s-0.3-2.8-1.6-2.8c-1.3,0-1.6,1.4-1.6,2.8S213.4,29.3,214.7,29.3z"/>
	<path className={styles.st0} d="M219.8,23.7c0-0.5,0-1-0.1-1.3h1.8c0,0.4,0.1,0.8,0.1,1.2h0c0.3-0.5,0.8-1.4,2.2-1.4c1.8,0,2.4,1.4,2.4,3v5.2
		h-2v-4.9c0-1.2-0.3-1.8-1.1-1.8c-1,0-1.4,0.9-1.4,1.9v4.7h-2V23.7z"/>
	<path className={styles.st0} d="M227.8,28.6c0.3,0.1,1.1,0.5,2,0.5c0.7,0,1.3-0.2,1.3-0.8c0-0.3-0.2-0.6-0.9-1l-0.8-0.5
		c-0.8-0.5-1.7-1-1.7-2.3c0-1.1,1.1-2.3,2.8-2.3c1,0,1.9,0.3,2,0.3l-0.1,1.5c-0.5-0.2-1-0.4-1.7-0.4c-0.8,0-1.1,0.3-1.1,0.8
		c0,0.4,0.3,0.7,0.9,1l1,0.6c1,0.6,1.5,1.1,1.5,2.1c0,1.5-1.3,2.4-3,2.4c-1,0-1.9-0.2-2.3-0.4L227.8,28.6z"/>
	<path className={styles.st0} d="M240.8,19.5v7.1c0,1.8,0.6,2.4,1.7,2.4c1.2,0,1.7-0.6,1.7-2.4v-7.1h2v7.1c0,2.9-1.7,4-3.7,4
		c-2.1,0-3.8-1.1-3.8-4v-7.1H240.8z"/>
	<path className={styles.st0} d="M248.2,23.7c0-0.5,0-1-0.1-1.3h1.8c0,0.4,0.1,0.8,0.1,1.2h0c0.3-0.5,0.8-1.4,2.2-1.4c1.8,0,2.4,1.4,2.4,3v5.2
		h-2v-4.9c0-1.2-0.3-1.8-1.1-1.8c-1,0-1.4,0.9-1.4,1.9v4.7h-2V23.7z"/>
	<path className={styles.st0} d="M256.6,18.9h2v1.9h-2V18.9z M256.6,22.4h2v8.1h-2V22.4z"/>
	<path className={styles.st0} d="M266.5,30.1c-0.4,0.2-1.3,0.5-2.4,0.5c-2.6,0-3.8-1.9-3.8-4.1c0-2.4,1.3-4.3,3.4-4.3c1.8,0,3.3,1,3.3,4.3V27
		h-4.7c0,1.4,0.6,2.2,2.1,2.2c1.2,0,1.8-0.4,2.1-0.6L266.5,30.1z M265,25.7c0-1.4-0.5-2.2-1.4-2.2c-1,0-1.5,1.1-1.5,2.2H265z"/>
	<path className={styles.st0} d="M268.2,28.6c0.3,0.1,1.1,0.5,2,0.5c0.7,0,1.3-0.2,1.3-0.8c0-0.3-0.2-0.6-0.9-1l-0.8-0.5
		c-0.8-0.5-1.7-1-1.7-2.3c0-1.1,1.1-2.3,2.8-2.3c1,0,1.9,0.3,2,0.3l-0.1,1.5c-0.5-0.2-1-0.4-1.7-0.4c-0.8,0-1.1,0.3-1.1,0.8
		c0,0.4,0.3,0.7,0.9,1l1,0.6c1,0.6,1.5,1.1,1.5,2.1c0,1.5-1.3,2.4-3,2.4c-1,0-1.9-0.2-2.3-0.4L268.2,28.6z"/>
	<path className={styles.st0} d="M67.5,41.6c0-0.6,0-1.1-0.1-1.4h1.8c0,0.2,0.1,0.7,0.1,1.3h0c0.3-0.7,0.9-1.4,2.2-1.4c2,0,2.9,1.9,2.9,4.2
		c0,2.7-1.1,4.2-2.8,4.2c-1.2,0-1.8-0.7-2.1-1.3h0v4.6h-2V41.6z M71,47.1c1.1,0,1.5-1.1,1.5-2.7c0-1.9-0.3-2.8-1.4-2.8
		c-1.2,0-1.6,1.2-1.6,2.8C69.4,45.9,69.9,47.1,71,47.1z"/>
	<path className={styles.st0} d="M79.3,40.1c1.7,0,3.6,0.9,3.6,4.2c0,3.4-1.9,4.2-3.6,4.2s-3.6-0.8-3.6-4.2C75.7,41,77.6,40.1,79.3,40.1z
		 M79.3,47.1c1.3,0,1.6-1.5,1.6-2.8c0-1.4-0.3-2.8-1.6-2.8c-1.3,0-1.6,1.4-1.6,2.8C77.7,45.7,78,47.1,79.3,47.1z"/>
	<path className={styles.st0} d="M90.9,47c0,0.5,0,1,0.1,1.3h-1.8c0-0.4-0.1-0.8-0.1-1.2l0,0l0,0c-0.4,0.6-0.9,1.4-2.2,1.4
		c-1.8,0-2.4-1.4-2.4-3v-5.2h2v4.9c0,1.2,0.3,1.8,1.2,1.8c1,0,1.3-0.9,1.3-2v-4.7h2V47z"/>
	<path className={styles.st0} d="M92.7,41.6c0-0.5,0-1-0.1-1.3h1.8c0,0.5,0.1,1,0.1,1.5h0c0.2-0.6,0.9-1.6,2.1-1.6c0.1,0,0.2,0,0.3,0V42
		c-0.2,0-0.3-0.1-0.6-0.1c-0.8,0-1.7,0.5-1.7,2v4.5h-2V41.6z"/>
	<path className={styles.st0} d="M102.5,36.5h2v11.9h-2V36.5z"/>
	<path className={styles.st0} d="M108.9,36.5l-1.2,3.9h-1.5l0.8-3.9H108.9z"/>
	<path className={styles.st0} d="M110.9,40.7c0.6-0.3,1.3-0.6,2.6-0.6c2.5,0,3.1,1.2,3.1,3.3v3.6c0,0.6,0.1,1.1,0.1,1.4h-1.8
		c-0.1-0.3-0.1-0.7-0.1-1.1h0c-0.5,0.7-1.1,1.2-2.3,1.2c-1.3,0-2.4-0.9-2.4-2.4c0-2.2,1.8-2.8,4.3-2.8h0.4v-0.3
		c0-0.8-0.4-1.5-1.5-1.5c-1,0-1.9,0.5-2.2,0.8L110.9,40.7z M114.8,44.4h-0.2c-1.7,0-2.7,0.3-2.7,1.5c0,0.7,0.5,1.2,1.2,1.2
		c1.2,0,1.6-0.9,1.6-2.3V44.4z"/>
	<path className={styles.st0} d="M118.6,36.5h2v11.9h-2V36.5z"/>
	<path className={styles.st0} d="M122.7,36.8h2v1.9h-2V36.8z M122.7,40.2h2v8.1h-2V40.2z"/>
	<path className={styles.st0} d="M126.6,41.6c0-0.6,0-1-0.1-1.3h2c0,0.4,0.1,0.9,0.1,1.3h0c0.3-0.6,0.9-1.4,2.1-1.4c1.4,0,1.8,0.9,2.1,1.3
		c0.4-0.6,1-1.3,2.2-1.3c1.8,0,2.3,1.3,2.3,3.2v5h-2v-5.1c0-1-0.3-1.5-1-1.5c-0.9,0-1.3,0.8-1.3,1.7v4.9h-2v-5.1c0-1-0.3-1.5-1-1.5
		c-0.9,0-1.3,0.8-1.3,1.7v4.9h-2V41.6z"/>
	<path className={styles.st0} d="M144.9,48c-0.4,0.2-1.3,0.5-2.4,0.5c-2.6,0-3.8-1.9-3.8-4.1c0-2.4,1.3-4.3,3.4-4.3c1.8,0,3.3,1,3.3,4.3v0.4
		h-4.7c0,1.4,0.6,2.2,2.1,2.2c1.2,0,1.8-0.4,2.1-0.6L144.9,48z M143.4,43.6c0-1.4-0.5-2.2-1.4-2.2c-1,0-1.5,1.1-1.5,2.2H143.4z"/>
	<path className={styles.st0} d="M146.8,41.6c0-0.5,0-1-0.1-1.3h1.8c0,0.4,0.1,0.8,0.1,1.2h0c0.3-0.5,0.8-1.4,2.2-1.4c1.8,0,2.4,1.4,2.4,3v5.2
		h-2v-4.9c0-1.2-0.3-1.8-1.1-1.8c-1,0-1.4,0.9-1.4,1.9v4.7h-2V41.6z"/>
	<path className={styles.st0} d="M155.8,38.6l2-0.6v2.3h1.6v1.4h-1.6v4.2c0,0.9,0.3,1.1,0.9,1.1c0.3,0,0.6-0.1,0.7-0.2v1.4
		c-0.3,0.1-0.7,0.3-1.3,0.3c-1.5,0-2.3-0.7-2.3-2.4v-4.5h-1.3v-1.4h1.3V38.6z"/>
	<path className={styles.st0} d="M161,40.7c0.6-0.3,1.3-0.6,2.6-0.6c2.5,0,3.1,1.2,3.1,3.3v3.6c0,0.6,0.1,1.1,0.1,1.4H165
		c-0.1-0.3-0.1-0.7-0.1-1.1h0c-0.5,0.7-1.1,1.2-2.3,1.2c-1.3,0-2.4-0.9-2.4-2.4c0-2.2,1.8-2.8,4.3-2.8h0.4v-0.3
		c0-0.8-0.4-1.5-1.5-1.5c-1,0-1.8,0.5-2.2,0.8L161,40.7z M164.8,44.4h-0.2c-1.7,0-2.7,0.3-2.7,1.5c0,0.7,0.5,1.2,1.2,1.2
		c1.2,0,1.6-0.9,1.6-2.3V44.4z"/>
	<path className={styles.st0} d="M169.2,38.6l2-0.6v2.3h1.6v1.4h-1.6v4.2c0,0.9,0.3,1.1,0.9,1.1c0.3,0,0.6-0.1,0.7-0.2v1.4
		c-0.3,0.1-0.7,0.3-1.3,0.3c-1.5,0-2.3-0.7-2.3-2.4v-4.5h-1.3v-1.4h1.3V38.6z"/>
	<path className={styles.st0} d="M174.2,36.8h2v1.9h-2V36.8z M174.2,40.2h2v8.1h-2V40.2z"/>
	<path className={styles.st0} d="M181.5,40.1c1.7,0,3.6,0.9,3.6,4.2c0,3.4-1.9,4.2-3.6,4.2c-1.7,0-3.6-0.8-3.6-4.2
		C177.9,41,179.9,40.1,181.5,40.1z M181.5,47.1c1.3,0,1.6-1.5,1.6-2.8c0-1.4-0.3-2.8-1.6-2.8c-1.3,0-1.6,1.4-1.6,2.8
		C180,45.7,180.3,47.1,181.5,47.1z"/>
	<path className={styles.st0} d="M186.7,41.6c0-0.5,0-1-0.1-1.3h1.8c0,0.4,0.1,0.8,0.1,1.2h0c0.3-0.5,0.8-1.4,2.2-1.4c1.8,0,2.4,1.4,2.4,3v5.2
		h-2v-4.9c0-1.2-0.3-1.8-1.1-1.8c-1,0-1.4,0.9-1.4,1.9v4.7h-2V41.6z"/>
	<path className={styles.st0} d="M204.9,48c-0.4,0.2-1.3,0.5-2.4,0.5c-2.6,0-3.8-1.9-3.8-4.1c0-2.4,1.3-4.3,3.4-4.3c1.8,0,3.3,1,3.3,4.3v0.4
		h-4.7c0,1.4,0.6,2.2,2.1,2.2c1.2,0,1.8-0.4,2.1-0.6L204.9,48z M203.5,43.6c0-1.4-0.5-2.2-1.4-2.2c-1,0-1.5,1.1-1.5,2.2H203.5z"/>
	<path className={styles.st0} d="M207.6,38.6l2-0.6v2.3h1.6v1.4h-1.6v4.2c0,0.9,0.3,1.1,0.9,1.1c0.3,0,0.6-0.1,0.7-0.2v1.4
		c-0.3,0.1-0.7,0.3-1.3,0.3c-1.5,0-2.3-0.7-2.3-2.4v-4.5h-1.3v-1.4h1.3V38.6z"/>
	<path className={styles.st0} d="M216.7,36.5h2v11.9h-2V36.5z"/>
	<path className={styles.st0} d="M223.2,36.5l-1.2,3.9h-1.5l0.8-3.9H223.2z"/>
	<path className={styles.st0} d="M225.2,40.7c0.6-0.3,1.3-0.6,2.6-0.6c2.5,0,3.1,1.2,3.1,3.3v3.6c0,0.6,0.1,1.1,0.1,1.4h-1.8
		c-0.1-0.3-0.1-0.7-0.1-1.1h0c-0.5,0.7-1.1,1.2-2.3,1.2c-1.3,0-2.4-0.9-2.4-2.4c0-2.2,1.8-2.8,4.3-2.8h0.4v-0.3
		c0-0.8-0.4-1.5-1.5-1.5c-1,0-1.8,0.5-2.2,0.8L225.2,40.7z M229,44.4h-0.2c-1.7,0-2.7,0.3-2.7,1.5c0,0.7,0.5,1.2,1.2,1.2
		c1.2,0,1.6-0.9,1.6-2.3V44.4z"/>
	<path className={styles.st0} d="M232.9,49.7c0.5,0.3,1.4,0.7,2.4,0.7c1.8,0,2.1-1.3,2.1-2.6V47h0c-0.3,0.6-0.9,1.4-2.2,1.4
		c-1.1,0-2.8-0.8-2.8-4c0-2.2,0.8-4.2,2.9-4.2c1.2,0,1.7,0.7,2.1,1.4h0.1c0-0.4,0-0.8,0.1-1.3h1.8c0,0.4-0.1,0.9-0.1,1.3v6
		c0,2.5-0.8,4.4-3.8,4.4c-1.3,0-2.3-0.3-2.8-0.5L232.9,49.7z M235.7,46.9c1.2,0,1.7-0.9,1.7-2.7c0-1.7-0.6-2.6-1.6-2.6
		c-1,0-1.4,1-1.4,2.6C234.5,46.1,235.1,46.9,235.7,46.9z"/>
	<path className={styles.st0} d="M241.2,41.6c0-0.5,0-1-0.1-1.3h1.8c0,0.5,0.1,1,0.1,1.5h0c0.2-0.6,0.9-1.6,2.1-1.6c0.1,0,0.2,0,0.3,0V42
		c-0.2,0-0.3-0.1-0.6-0.1c-0.8,0-1.7,0.5-1.7,2v4.5h-2V41.6z"/>
	<path className={styles.st0} d="M246.9,36.8h2v1.9h-2V36.8z M246.9,40.2h2v8.1h-2V40.2z"/>
	<path className={styles.st0} d="M256.4,48.2c-0.5,0.2-1.1,0.3-1.9,0.3c-2.7,0-3.8-1.9-3.8-4.2c0-2.3,1.2-4.2,3.9-4.2c0.6,0,1.3,0.2,1.7,0.3
		l-0.1,1.5c-0.3-0.2-0.8-0.4-1.4-0.4c-1.5,0-2.1,1-2.1,2.7c0,1.5,0.7,2.7,2.2,2.7c0.5,0,1.1-0.2,1.4-0.4L256.4,48.2z"/>
	<path className={styles.st0} d="M264,47c0,0.5,0,1,0.1,1.3h-1.8c0-0.4-0.1-0.8-0.1-1.2l0,0l0,0c-0.4,0.6-0.9,1.4-2.2,1.4c-1.8,0-2.4-1.4-2.4-3
		v-5.2h2v4.9c0,1.2,0.3,1.8,1.2,1.8c1,0,1.3-0.9,1.3-2v-4.7h2V47z"/>
	<path className={styles.st0} d="M265.9,36.5h2v11.9h-2V36.5z"/>
	<path className={styles.st0} d="M270.6,38.6l2-0.6v2.3h1.6v1.4h-1.6v4.2c0,0.9,0.3,1.1,0.9,1.1c0.3,0,0.6-0.1,0.7-0.2v1.4
		c-0.3,0.1-0.7,0.3-1.3,0.3c-1.5,0-2.3-0.7-2.3-2.4v-4.5h-1.3v-1.4h1.3V38.6z"/>
	<path className={styles.st0} d="M281.9,47c0,0.5,0,1,0.1,1.3h-1.8c0-0.4-0.1-0.8-0.1-1.2l0,0l0,0c-0.4,0.6-0.9,1.4-2.2,1.4
		c-1.9,0-2.4-1.4-2.4-3v-5.2h2v4.9c0,1.2,0.3,1.8,1.2,1.8c1,0,1.3-0.9,1.3-2v-4.7h2V47z"/>
	<path className={styles.st0} d="M283.7,41.6c0-0.5,0-1-0.1-1.3h1.8c0,0.5,0.1,1,0.1,1.5h0c0.2-0.6,0.9-1.6,2.1-1.6c0.1,0,0.2,0,0.3,0V42
		c-0.2,0-0.3-0.1-0.6-0.1c-0.8,0-1.7,0.5-1.7,2v4.5h-2V41.6z"/>
	<path className={styles.st0} d="M295,48c-0.4,0.2-1.3,0.5-2.4,0.5c-2.6,0-3.8-1.9-3.8-4.1c0-2.4,1.3-4.3,3.4-4.3c1.8,0,3.3,1,3.3,4.3v0.4h-4.7
		c0,1.4,0.6,2.2,2.1,2.2c1.2,0,1.8-0.4,2.1-0.6L295,48z M293.5,43.6c0-1.4-0.5-2.2-1.4-2.2c-1,0-1.5,1.1-1.5,2.2H293.5z"/>
</g>
    </svg>
);

export default FaoLogo;
