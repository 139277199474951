import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Header from '../components/_fr/Header-fr';
import Footer from '../components/_fr/Footer-fr';
import styles from './index.module.scss';

export default class FrHomePage extends PureComponent {
  render() {
    const { data: { site } } = this.props;

    return (
      <div>
        <Helmet
          title={site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'FAO AQUASTAT' },
            { name: 'keywords', content: 'fao, water, aquastat, water production' },
          ]}
        />
        <Header siteTitle={site.siteMetadata.title} location={{}} />

        <div className="app-wrapper">
          <div className={styles.wrapper}>
          <p>
            La version française de ce site sera bientôt disponible. Désolés pour tout désagrément.
            Entretemps, veuillez consulter <a href="http://www.fao.org/nr/water/aquastat/main/indexfra.stm">l'ancienne version du site web d'AQUASTAT</a>
          </p>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

FrHomePage.propTypes = {
  data: PropTypes.object.isRequired,
};

/* eslint-disable no-undef */
export const pageQuery = graphql`
  query FrHomePageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
